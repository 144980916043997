import React, { useContext } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { UserContext } from "../UserContext";
import { useRouter } from "next/router";
import { toast } from "react-toastify";
import { AffiliateRoutes } from "../../pages/ambassador";

interface Props {
    closeSetPassword: () => void;
}
const toastOptions = {
    autoClose:3000
  }
const ReferralSetPasswordModal = (props: Props) => {
    const { setloader } = useContext(UserContext);
    const router = useRouter();


    const handleModalClose = () => {
        props.closeSetPassword();
    }

    const formik = useFormik({
        initialValues: {
            password: '',
            password_confirmation: '',
        },
        validationSchema: yup.object({
            password: yup
                .string()
                .required("Password is required"),
            password_confirmation: yup
                .string()
                .required("Confirm Password is required")
                .oneOf([yup.ref('password')], 'Passwords do not match.'),
        }),
        onSubmit: (formData: any) => {
            handleReferralSetPassword(formData);
        },
    });

    const handleReferralSetPassword = async (formData: any) => {
        setloader(true);

        try {

            const res = await fetch(process.env.API_URL + "referral/reset-password", {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ ...formData, token: router.query.token }),
            });
            const result: any = await res.json();
            if (res.ok) {
                if (result) {
                    router.push({ pathname: AffiliateRoutes.login })
                    toast.success("Password updated successfully",toastOptions);
                    setloader(false);
                }
                return;
            }

            if (result?.errors?.length) {
                console.log(result)
                toast.error(result.errors[0],toastOptions);
            }
            setloader(false);
        } catch (e: any) {
            setloader(false);
            toast.error(e?.message,toastOptions);
            return null;
        }
    };

    return (<>

        <div className="">

            <div className="lead relative flex-col-reverse md:flex-row">
                <div className="pt-16 p-4 md:p-8 md:py-14 md:px-12">
                    {/* <div className="EstimateDetailModal__Overlay form-control-overlay otp-dialog"> */}

                    <div className="relative">
                        <svg onClick={() => { handleModalClose() }} className="w-8 h-8 text-bodyColor float-right absolute -top-12  -right-2 md:-right-10 mr-0.5 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M6 18L18 6M6 6l12 12"></path></svg>
                        <h2 className="text-left mb-3 text-2xl font-primaryMedium text-titleColor">
                            Set New Password
                        </h2>
                        <form onSubmit={formik.handleSubmit} action="#" method="POST">
                            <div className="grid grid-cols-1 gap-4">
                                <div className="relative">
                                    <p className="mb-1 text-sm xxs:text-base text-titleColor">
                                        New Password <span className="text-primary"> *</span>
                                        {formik.touched.password &&
                                            formik.errors.password ? (
                                            <span className="text-red-500 text-xs w-full ml-2">
                                                {formik.errors.password}
                                            </span>
                                        ) : null}
                                    </p>
                                    <input
                                        placeholder="Password"
                                        {...formik.getFieldProps("password")}
                                        name="password"
                                        type="password"
                                        autoComplete="new-password"
                                        className="text-sm md:text-base focus:ring-2 focus:ring-titleColor focus:ring-offset-white  focus:outline-none p-2.5 border border-gray-300 rounded w-full placeholder-current text-bodyColor"
                                    />
                                </div>
                                <div className="relative">
                                    <p className="mb-1 text-sm xxs:text-base text-titleColor">
                                        Confirm Password <span className="text-primary"> *</span>
                                        {formik.touched.password_confirmation &&
                                            formik.errors.password_confirmation ? (
                                            <span className="text-red-500 text-xs w-full ml-2">
                                                {formik.errors.password_confirmation}
                                            </span>
                                        ) : null}
                                    </p>
                                    <input
                                        placeholder="Confirm Password"
                                        {...formik.getFieldProps("password_confirmation")}
                                        name="password_confirmation"
                                        type="password"
                                        autoComplete="new-password"
                                        className="text-sm md:text-base focus:ring-2 focus:ring-titleColor focus:ring-offset-white  focus:outline-none p-2.5 border border-gray-300 rounded w-full placeholder-current text-bodyColor"
                                    />
                                </div>
                            </div>

                            <button disabled={!formik.isValid}

                                type="submit"
                                className="disabled:opacity-50 block p-3 my-4 mt-8 w-full hover:shadow-lg bg-primary text-white text-sm 2xl:text-lg xxs:text-lg font-primaryRegular uppercase text-center rounded"
                            >
                                Set Password
                            </button>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </>);
};

export default ReferralSetPasswordModal;