import Link from "next/link";
import ExternalLinks from "../Common/ExternalLinks";
import { useOrgTheme } from "../../../context/ThemeContext";
import { useRouter } from "next/router";
import { AffiliateRoutes } from "../../../pages/ambassador";

const MobileHeader = (props: any) => {
  const {
    setMenutoggle,
    openMobileMenuView,
    setMobileView,
    showGoToCurrentPurchaseButton,
    goToCurrentPurchase,
  } = props;
  const { getOrgSettings, getTheme } = useOrgTheme();
  const { show_affialiate, external_links } = getOrgSettings();
  const route = useRouter();
  const { contactNumbers = [] } = getTheme();
  const contactNumber: any = contactNumbers[0];
  return (
    <nav className="px-4 grid gap-4 relative z-100">
      <div className="space-y-1">
        <div
          className="flex items-center justify-between"
          onClick={() => setMenutoggle(1)}
        >
          <span className="focus:outline-none cursor-pointer flex items-center px-3 text-xl xxs:text-2xl font-primaryRegular rounded-md text-bodyColor hover:text-primary">
            Products
          </span>
          <svg
            className={
              "ml-auto h-6 w-6 text-bodyColor text-opacity-80 transform group-hover:text-primary transition-colors ease-in-out duration-150 " +
              (openMobileMenuView == 1 ? "rotate-180" : "rotate-90")
            }
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 15l7-7 7 7"
            ></path>
          </svg>
        </div>
        <div
          className={
            "accordion__panel accordion_sidebar sub-menu grid gap-2 " +
            (openMobileMenuView == 1 ? " menu-up grid" : " menu-down hidden")
          }
        >
          <Link href="/solar">
            <a
              onClick={() => setMobileView(false)}
              className={`focus:text-primary text-base xxs:text-lg font-primaryMedium text-gray-500 text-opacity-80 rounded-md px-4 ${
                route.pathname === "/solar" ? "text-primary" : "text-titleColor"
              }`}
            >
              Solar
            </a>
          </Link>
          <Link href="/battery">
            <a
              onClick={() => setMobileView(false)}
              className={`focus:text-primary text-base xxs:text-lg font-primaryMedium text-gray-500 text-opacity-80 rounded-md px-4 ${
                route.pathname === "/battery"
                  ? "text-primary"
                  : "text-titleColor"
              }`}
            >
              Battery
            </a>
          </Link>
        </div>
      </div>
      <Link href="/whySolar">
        <a
          onClick={() => setMobileView(false)}
          className="focus:outline-none flex items-center px-3 text-xl xxs:text-2xl font-primaryRegular rounded-md text-bodyColor hover:text-primary"
        >
          Why Solar{" "}
        </a>
      </Link>
      {/* <Link href="/company" >
<span className="focus:text-primary focus:outline-none flex items-center px-3 text-xl font-primaryRegular rounded-md bg-white text-bodyColor text-opacity-60 hover:text-titleColor hover:bg-gray-50">Company{" "}</span>
</Link> */}
      <div className="space-y-1">
        <div
          className="flex items-center justify-between"
          onClick={() => setMenutoggle(2)}
        >
          <span className="focus:outline-none cursor-pointer flex items-center px-3 text-xl xxs:text-2xl font-primaryRegular rounded-md text-bodyColor hover:text-primary">
            Company
          </span>
          <svg
            className={
              "ml-auto h-6 w-6 text-bodyColor text-opacity-80 transform group-hover:text-primary transition-colors ease-in-out duration-150 " +
              (openMobileMenuView == 2 ? "rotate-180" : "rotate-90")
            }
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 15l7-7 7 7"
            ></path>
          </svg>
        </div>

        <div
          className={
            "accordion__panel accordion_sidebar sub-menu grid gap-2 " +
            (openMobileMenuView == 2 ? "menu-up grid" : "menu-down hidden")
          }
        >
          <Link href="/company">
            <a
              onClick={() => setMobileView(false)}
              className={`focus:text-primary text-base xxs:text-lg font-primaryMedium text-gray-500 text-opacity-80 rounded-md hover:text-titleColor hover:bg-gray-50 px-4 ${
                route.pathname === "/company"
                  ? "text-primary"
                  : "text-titleColor"
              }`}
            >
              About us
            </a>
          </Link>
          <Link href="/resources">
            <a
              onClick={() => setMobileView(false)}
              className={`focus:text-primary text-base xxs:text-lg font-primaryMedium text-gray-500 text-opacity-80 rounded-md px-4 ${
                route.pathname === "/resources"
                  ? "text-primary"
                  : "text-titleColor"
              }`}
            >
              Resources
            </a>
          </Link>
          <Link href="/blog">
            <a
              onClick={() => setMobileView(false)}
              className={`focus:text-primary text-base xxs:text-lg font-primaryMedium text-gray-500 text-opacity-80 rounded-md px-4 ${
                route.pathname === "/blog" ? "text-primary" : "text-titleColor"
              }`}
            >
              Blog
            </a>
          </Link>
        </div>
      </div>

      {/* <div className="space-y-1">
  <div
    className="flex items-center justify-between"
    onClick={() => setMenutoggle(3)}
  >
    <span className="focus:outline-none cursor-pointer flex items-center px-3 text-xl xxs:text-2xl font-primaryRegular rounded-md text-bodyColor hover:text-primary">
      Location
    </span>
    <svg
      className={
        "ml-auto h-6 w-6 text-bodyColor text-opacity-80 transform group-hover:text-primary transition-colors ease-in-out duration-150 " +
        (openMobileMenuView == 3
          ? "rotate-180"
          : "rotate-90")
      }
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 15l7-7 7 7"
      ></path>
    </svg>
  </div>
  <div
    className={
      "accordion__panel accordion_sidebar sub-menu grid gap-2 " +
      (openMobileMenuView == 3
        ? "menu-up grid"
        : "menu-down hidden")
    }
  >
    {props?.landingPages.map(
      (page: any, index: number) => (
        <Link href={`/${page.slug}`} key={index}>
          <a
            onClick={() => setMobileView(false)}
            className="focus:text-primary text-base xxs:text-lg font-primaryMedium text-gray-500 text-opacity-80 rounded-md px-4"
          >
            {page.heading2}
          </a>
        </Link>
      )
    )}
  </div>
</div> */}
      <Link href="/support">
        <a
          className="focus:outline-none flex items-center px-3 text-xl xxs:text-2xl font-primaryRegular rounded-md text-bodyColor hover:text-primary"
          onClick={() => setMobileView(false)}
        >
          Support
        </a>
      </Link>
      {show_affialiate && (
        <Link href={AffiliateRoutes.index}>
          <a
            className="focus:outline-none flex items-center px-3 text-xl xxs:text-2xl font-primaryRegular rounded-md text-bodyColor hover:text-primary"
            onClick={() => setMobileView(false)}
          >
            Become An Ambassador
          </a>
        </Link>
      )}
      <ExternalLinks
        data={external_links}
        className="focus:outline-none flex items-center px-3 text-xl xxs:text-2xl font-primaryRegular rounded-md text-bodyColor hover:text-primary"
      />
      {!props.hideCurrentPurchaseAndTrackYourPurchaseButtons && (
        <div className="my-auto">
          {showGoToCurrentPurchaseButton && (
            <span
              className="focus:outline-none flex items-center px-3 text-xl xxs:text-2xl font-primaryRegular rounded-md text-bodyColor hover:text-primary cursor-pointer"
              onClick={goToCurrentPurchase}
            >
              {" "}
              Current purchase
            </span>
          )}
        </div>
      )}

      <div className="mt-3 space-y-3">
        <Link href={`tel:${contactNumber}`}>
          <a
            onClick={() => setMobileView(false)}
            className="focus:text-primary text-lg font-primaryMedium text-bodyColor text-opacity-80 rounded-md hover:text-titleColor hover:bg-gray-50 px-2 flex flex-row items-center mobile-se-text"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
              />
            </svg>
            <span className="leading-tight">{contactNumber}</span>
          </a>
        </Link>
        <Link href="mailto:customerservice@solarquote.com">
          <a
            onClick={() => setMobileView(false)}
            className="focus:text-primary text-lg font-primaryMedium text-bodyColor text-opacity-80 rounded-md hover:text-titleColor hover:bg-gray-50 px-2 flex flex-row items-center mobile-se-text"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
            <span className="leading-tight pl-1">
              customerservice@solarquote.com
            </span>
          </a>
        </Link>
      </div>
      {/* <div className="space-y-1" >
<span className="flex items-center justify-between">
<Link href="/support">
<a className="focus:outline-none flex items-center px-3 text-xl xxs:text-2xl font-primaryRegular rounded-md text-bodyColor hover:text-primary">
Support
</a>
</Link>
<svg onClick={() => setMenutoggle(4)} className={"ml-auto h-6 w-6 text-bodyColor text-opacity-80 transform group-hover:text-primary transition-colors ease-in-out duration-150 " + (openMobileMenuView == 4 ? 'rotate-180' : 'rotate-90')} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7"></path></svg>
</span>
<div className={"grid gap-2 " + (openMobileMenuView== 4 ? 'menu-up' : 'menu-down')}>
<Link href="tel:(888)708-3756">
<a className="focus:text-primary text-base xxs:text-lg font-primaryMedium text-bodyColor text-opacity-80 rounded-md hover:text-titleColor hover:bg-gray-50 px-4">
Phone
</a>
</Link>
<Link  href="mailto:customerservice@solarquote.com">
<a className="focus:text-primary text-base xxs:text-lg font-primaryMedium text-bodyColor text-opacity-80 rounded-md hover:text-titleColor hover:bg-gray-50 px-4">
Email
</a>
</Link>
</div>
</div> */}
    </nav>
  );
};
export default MobileHeader;
