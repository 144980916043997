import Modal from "react-modal";
import { useContext, useState } from "react";
import TalkToExpertDialog from "../Estimate/Modal/TalkToExpertDialog";
import ThemeContext from "../../context/ThemeContext";

declare global {
  interface Window {
    drift?: any;
  }
}

const TalkToExpert = () => {
  const [isExpertOpen, setIsExpertOpen] = useState<boolean>(false);

  const { isClientOrg, isSuntuity, getTheme } = useContext(ThemeContext);
  const { talkToExpert = {} } = getTheme();
  const openExpertModal = () => {
    setIsExpertOpen(true);
  };
  const closeExpertModal = () => {
    console.log("closeExpertModal");
    setIsExpertOpen(false);
    document.body.classList.add("hide-drift");
  };

  const isDrift = () => {
    return !!window?.drift || !!window?.LiveChatWidget || window?.$_Tawk;
  };

  if (
    !talkToExpert ||
    (Object.values(talkToExpert || {}).every((val) => !val) && !isDrift())
  ) {
    return <></>;
  }

  return (
    <>
      <Modal
        preventScroll={false}
        isOpen={isExpertOpen}
        portalClassName={"EstimateDetailModalPortal"}
        htmlOpenClassName={"EstimateDetailModal__Html--open"}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        overlayClassName={"AmountModal__Overlay TalkToExpertDialog"}
        ariaHideApp={false}
      >
        <div className="estimate-modal-wraaper relative">
          <TalkToExpertDialog
            closeExpertModal={closeExpertModal}
          ></TalkToExpertDialog>
        </div>
      </Modal>

      <button
        onClick={() => {
          openExpertModal();
        }}
        className={`talk-to-expert flex items-end  px-3 xl:px-4 py-2 border border-transparent text-sm gap-2 font-primaryMedium shadow-sm text-white hover:shadow hover:bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary cursor-pointer bg-secondary rounded whitespace-nowrap ${
          isClientOrg ? "customThemeButton" : ""
        } ${isSuntuity ? "self-end sm:self-center" : ""}`}
      >
        <svg
          className={`w-5 h-5  opacity-90 text-white`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
          ></path>
        </svg>
        <span>Talk To Expert</span>
      </button>
    </>
  );
};
export default TalkToExpert;
